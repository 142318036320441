

import IconPrinter from '@/components/svg/IconPrinter.vue'
import axios from 'axios'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ICustomFieldData } from 'smartbarcode-web-core/src/utils/types/model/fieldData'
import { ISmaPriTemplateConfig } from 'smartbarcode-web-core/src/utils/types/model/project'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { openMessage } from '@/utils/helpers'

@Options({
  components: {
    IconPrinter,
  },
})
export default class SmaPriSendButton extends Vue {
  @Prop({ type: String }) readonly projectCode?: string
  @Prop({ type: String }) readonly barcodeType?: string
  @Prop({ type: Object }) readonly customFields?: Record<string, ICustomFieldData>
  @Prop({ type: String }) readonly barcodeId?: string
  url = ''
  async print() {
    await axios.get(this.url)
      .then(response => {
        openMessage(this.$t('Done'), 'success')
      })
      .catch(error => {
        const errorMessage = error.message ?? error ?? 'error'
        openMessage(errorMessage, 'error')
      })
  }

  mounted() {
    this.url = this.makeFormatPrintUrl()
  }

  makeFormatPrintUrl() {
    const barcodeType = this.barcodeType ?? ''
    const templateConfig = this.$store.state.project.details.barcodeTypes?.[barcodeType]?.smapriTemplateConfig as ISmaPriTemplateConfig
    if (!templateConfig) {
      return ''
    }

    const smapriPrintEndpoint = 'http://localhost:8080/Format/Print'
    const bucketUrl = process.env.VUE_APP_BUCKET_URL ?? ''
    const formatUrl = encodeURIComponent(bucketUrl + templateConfig.templateFilePath)

    let url = smapriPrintEndpoint +
      '?__format_archive_url=' + formatUrl +
      '&__format_archive_update=update' +
      '&__format_id_number=1'
    templateConfig.templateFields.forEach(field => {
      if (field.fieldName === 'trackingNum') {
        const trackingNumber = this.$store?.getters?.trackingNumber
        url += `&${field.fieldName}=${trackingNumber}`
      } else if (field.fieldName === 'sbUrl') {
        const projectVersion = this.$store?.getters.projectVersion
        const smartbarcodeUrl = `${process.env.VUE_APP_BASE_URL}/${this.projectCode}@${projectVersion}/qrcode/${this.barcodeId}`
        url += `&${field.fieldName}=${encodeURIComponent(smartbarcodeUrl)}`
      } else {
        const customFieldValue = this.getCustomFieldValue(field.key)
        url += `&${field.fieldName}=${customFieldValue}`
      }
    })
    url += '&(発行枚数)=1'
    return url
  }

  getCustomFieldValue(key: string): string {
    const field = this.customFields?.[key]
    if (!field) {
      return ''
    }
    const fieldType = field.fieldType
    switch (fieldType) {
      case ECustomFieldType.TEXT:
        return field.text ?? ''
      case ECustomFieldType.DATE:
        return field.date ?? ''
      case ECustomFieldType.NUMBER:
        return field.number?.toString() ?? ''
      case ECustomFieldType.SINGLE_SELECT:
        return field.singleSelect?.value ?? ''
      case ECustomFieldType.MULTI_SELECT:
        return field.multiSelect?.map(select => select.value).join(', ') ?? ''
      case ECustomFieldType.PHONE_NUMBER:
        return field.phoneNumber?.number ?? ''
      case ECustomFieldType.EMAIL:
        return field.email ?? ''
      default:
        return ''
    }
  }
}

