/* eslint-disable @typescript-eslint/no-explicit-any */
const Linkify = {
  beforeMount: function(el: any) {
    el.innerHTML = el.innerHTML.replace(
      /\b((https?|ftp):\/\/[\w-]+(\.[\w-]+)+([^\s]*))\b/g,
      '<a href="$1" target="_blank">$1</a>'
    )
  },
}

export { Linkify }
